/* Style the loginPage container */
.loginPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
    background-image: url('https://img.freepik.com/free-vector/network-mesh-wire-digital-technology-background_1017-27428.jpg?w=1060&t=st=1695287272~exp=1695287872~hmac=ae4318718c7b2b45985edccad9650c934c763eb9f166b86257b5a988932d373c'); /* Replace 'your-background-image.jpg' with the path to your image */
    background-size: cover; /* Cover the entire container with the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    background-position: center;
}
span{
    color: #23004d;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 20px;

}

@media (max-width:900px) {
    span{
        font-size: 20px;
    }
}
@media (max-width:600px) {
    span{
        font-size: 18px;
    }
}
/* Style the form */
.loginPage form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* Rounded corners for a softer look */
    width: 20%; /* Adjust the width as needed */
    height: auto;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow */
    backdrop-filter: blur(5px); /* Apply a blur effect to the background */

}
@media (max-width: 900px) {
    .loginPage form {
        width: 50%; /* Adjusted width for smaller screens */
    }
}

/* Style the text inputs */
.loginInput {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
    padding: 16px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent input background */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    
}

/* Style the button */
.loginButton {
    box-sizing: border-box;
    background-color: #46009c;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

/* Style the button hover effect */
button:hover {
    background-color: #23004d;
    color: #fff;
}
