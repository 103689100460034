/* Style the loginPage container */
.profilePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
}
span{
    color: #23004d;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 20px;

}

@media (max-width:900px) {
    span{
        font-size: 20px;
    }
}
@media (max-width:600px) {
    span{
        font-size: 18px;
    }
}
/* Style the form */
.profilePage form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* Rounded corners for a softer look */
    width: 20%; /* Adjust the width as needed */
    /* height: 30%; */
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow */
    backdrop-filter: blur(5px); /* Apply a blur effect to the background */
}

@media (max-width: 900px) {
    .profilePage form {
        width: 50%; /* Adjusted width for smaller screens */
    }
}

/* Style the text inputs */
 .profileInput {
    padding: 16px;
    width: 90%;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent input background */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-size: 14px;
}

.profilePage .checkbox{
    max-width: fit-content;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #d30000;
    border-radius: 3px;
}

/* Style the button */
.profilePage button {
    background-color: #46009c;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

/* Style the button hover effect */
button:hover {
    background-color: #23004d;
    color: #fff;
}
